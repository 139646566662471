const el = {
    // SchemaDefinition titles //////////
    'dfTitle': 'Ιδιότητες',
    'dfTitleSeparator': '/',
    'dfTitles': {
        'person': 'Ιδιότητες Ατόμου'
    },

    // PropertyDefinition types but human-readable //////////
    'pdTypeLabels': {
        // These used when we have to resolve the type label from a PropertyDefinition.
        'false_STRING': 'Κείμενο', // As safe value if something goes wrong
        'false_STRING_false': 'Σύντομο Κείμενο',
        'false_STRING_true': 'Μεγάλο Κείμενο',
        'true_STRING': 'Λίστα Κειμένων',
        'true_STRING_false': 'Λίστα Σύντομων Κειμένων',
        'false_LONG': 'Αριθμός',
        'false_DOUBLE': 'Αριθμός',
        'false_BOOLEAN': 'Δυαδική Επιλογή',
        'false_INSTANT': 'Ημερομηνία',
        'false_ENUMERATION': 'Απαρίθμηση',
        // Any other combination is theoretically unreachable!

        // Without list prefix.
        // 'true_STRING_false': 'Short Text List',
        'STRING_false': 'Σύντομο Κείμενο',
        'STRING_true': 'Μεγάλο Κείμενο',
        'LONG': 'Αριθμός',
        'DOUBLE': 'Αριθμός',
        'BOOLEAN': 'Δυαδική Επιλογή',
        'INSTANT': 'Ημερομηνία',
        'ENUMERATION': 'Απαρίθμηση'
    },

    // Person domain //////////
    'person': {
        'df': {
            'pg': {
                'basic': 'Βασικά',
                'occupation': 'Απασχόληση',
                'demographics': 'Δημογραφικά',
                'naming': 'Ονόματα',
                'namingVoc': 'Ονόματα σε κλητική',
                'contact': 'Στοιχεία Επικοινωνίας',
                'social': 'Κοινωνικά Δίκτυα',
                'communication': 'Επικοινωνία',
                'taxonomy': 'Ταξινόμηση',
                'legal': 'Νομικά'
            },
            'pd': {
                // basic //////////
                'name': 'Πλήρες Όνομα',
                'avatarLetters': 'Γράμματα Avatar',
                'avatarUrl': 'Avatar URL',
                'firstName': 'Όνομα',
                'lastName': 'Επώνυμο',
                // occupation //////////
                'title': 'Τίτλος',
                'department': 'Τμήμα',
                'organization': 'Οργανισμός',
                'fullTitle': 'Πλήρης Τίτλος',
                // demographics //////////
                'sex': 'Φύλο',
                'dateOfBirth': 'Ημερομηνία Γέννησης',
                'age': 'Ηλικία',
                'deceased': 'Αποθανών/ουσα',
                'maritalStatus': 'Οικογενειακή Κατάσταση',
                'nationality': 'Εθνικότητα',
                'nativeLanguage': 'Μητρική Γλώσσα',
                // naming //////////
                'prefix': 'Πρόθεμα',
                'suffix': 'Πρόσφυμα',
                'nickname': 'Ψευδώνυμο',
                'legalFirstName': 'Νόμιμο (Βαπτιστικό) Όνομα',
                'maidenName': 'Πατρικό Όνομα',
                'fathersFirstName': 'Όνομα Πατρός',
                'fathersLastName': 'Επώνυμο Πατρός',
                'mothersFirstName': 'Όνομα Μητρός',
                'mothersLastName': 'Επώνυμο Μητρός',
                'phoneticFirstName': 'Φωνητικό Όνομα',
                'pronunciationFirstName': 'Προφορικό Όνομα',
                'phoneticLastName': 'Φωνητικό Επώνυμο',
                'pronunciationLastName': 'Προφορικό Επώνυμο',
                // namingVoc //////////
                'vocFirstName': 'Όνομα (Κλητική)',
                'vocLastName': 'Επώνυμο (Κλητική)',
                'vocLegalFirstName': 'Νόμιμο (Βαπτιστικό) Όνομα (Κλητική)',
                'vocMaidenName': 'Πατρικό Όνομα (Κλητική)',
                'vocNickname': 'Ψευδώνυμο Όνομα (Κλητική)',
                // contact //////////
                'email': 'Email',
                'emailDomain': 'Όνομα Τομέα Χώρου (domain) Email',
                'phoneNumber': 'Αριθμός Τηλεφώνου',
                'websiteUrl': 'Ιστοσελίδα',
                'websiteDomain': 'Όνομα Τομέα Χώρου (domain) Ιστοσελίδας',
                // social //////////
                'twitterUsername': 'Όνομα Χρήστη Twitter',
                'instagramUsername': 'Όνομα Χρήστη Instagram',
                'facebookUrl': 'Facebook Προφίλ URL',
                // communication //////////
                'preferredLangKey': 'Προτιμώμενη Γλώσσα',
                'doNotCommunicate': 'Μην επικοινωνήσετε',
                'bestTimeToCommunicate': 'Καλύτερη ώρα για επικοινωνία',
                // taxonomy //////////
                'priority': 'Προτεραιότητα',
                'labels': 'Ετικέτες',
                // legal //////////
                'lawfulBasisExists': 'Νομική Βάση',
                'lawfulBasisExplanation': 'Επεξήγηση Νομικής Βάσης'
            }
        },
        'entity': {
            'property': {
                'id': 'Αναγνωριστικό (ID)',
                'createdBy': 'Δημιουργήθηκε από Χρήστη',
                'createdDate': 'Ημερομηνία Δημιουργίας',
                'lastModifiedBy': 'Επεξεργάστηκε από Χρήστη',
                'lastModifiedDate': 'Ημερομηνία Τελευταίας Επεξεργασίας',
                'sourceType': 'Τύπος Πηγής',
                'sourceDescription': 'Περιγραφή Πηγής',
                'communicationSubscription': 'Συνδρομή Επικοινωνίας'
            },
            'group': {
                'origin': 'Προέλευση',
                'metadata': 'Μεταδεδομένα'
            }
        },
        'enum': {
            'sex': {
                'UNKNOWN': 'Άγνωστο',
                'MALE': 'Άρρεν',
                'FEMALE': 'Θήλυ',
                'OTHER': 'Άλλο'
            },
            'maritalStatus': {
                'UNKNOWN': 'Άγνωστο',
                'SINGLE': 'Άγγαμος/η',
                'MARRIED': 'Παντρεμένος/η',
                'WIDOWED': 'Χήρος/α',
                'DIVORCED': 'Διαζευγμένος/η',
                'SEPARATED': 'Χωρισμένος/η',
                'CIVIL_UNION': 'Σύμφωνο Συμβίωσης',
                // @see https://www.proz.com/kudoz/english-to-greek/law-general/2493832-domestic-partner.html
                'DOMESTIC_PARTNER': 'Ελεύθερη Συμβίωση',
                'OTHER': 'Άλλο'
            },
            'preferredLangKey': {
                'EL': 'Ελληνικά',
                'EN': 'Αγγλικά'
            },
            'sourceType': {
                'UNKNOWN': 'Άγνωστο',
                'SYSTEM': 'Σύστημα',
                'PORTAL': 'Διαδικτυακή Εφαρμογή',
                'API': 'API',
                'FILE_IMPORT': 'Εισαγωγή Αρχείου',
                'ONLINE_SOURCES': 'Online Πηγές',
                'OFFLINE_SOURCES': 'Offline Πηγές'
            }
        }
    }
};

export default el;
